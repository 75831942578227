<template>
  <div class="domains-main">
    <div class="domains-main__head">
      <div class="domains-main__title">
        <page-title>
          {{ $t('title') }}
        </page-title>
      </div>
      <tabs :list="filterNav" class="medium-title">
        <template v-slot:item="{ item }">
          <router-link exact-active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
      </tabs>
    </div>
    <div class="domains-main__body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
export default {
  name: 'DomainsMain',
  components: {
    tabs,
  },
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.main'),
          to: { name: 'domainsMain' },
        },
        {
          title: this.$t('nav.order'),
          to: { name: 'domainsOrder' },
        },
        {
          title: this.$t('nav.contact'),
          to: { name: 'domainsContact' },
          access: { name: 'isDomain', value: this.accessContacts },
        },
      ],
    };
  },
  computed: {
    filterNav() {
      return this.nav.filter(i => !i.access || (i.access && this[i.access.name] === true));
    },
    isDomain() {
      return (
        this.$store.state.moduleDomains.moduleDomainsContact.list.length > 0 ||
        this.$store.state.moduleDomains.moduleDomainsDomains.list.length > 0
      );
    },
    accessContacts() {
      return (
        this.$store.state.moduleDomains.moduleDomainsContact.list &&
        this.$store.state.moduleDomains.moduleDomainsContact.list.length > 0
      );
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleDomains/moduleDomainsContact/fetchList');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Домены и DNS",
    "nav": {
      "main": "Список доменов и поддоменов",
      "order": "Зарегистрировать домен",
      "contact": "Контакты доменов"
    },
    "order": "Купить домен",
    "add": "Добавить домен"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domains-main {
  &__title {
    flexy(space-between, center);
    margin-bottom: 1.5rem;
  }
  &__body {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
